import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "hvwlink"
    }
  }, [_vm.error ? _c('div', {
    staticClass: "overflower"
  }, [_c('h1', {
    staticClass: "pad"
  }, [_vm._v("Keine Daten vorhanden!")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Dieses Dokument existiert nicht oder wurde bereits für die Bearbeitung gesperrt.")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Bei Fragen oder technischen Störungen kontaktieren Sie bitte Herrn Matthias Lex unter der Telefonnummer +43 2704104 313 oder per Email office@consense.at.")])]) : _vm._e(), !_vm.error ? _c('div', {
    staticClass: "overflower"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Um den versicherungstechnischen Wert eines Gebäudes beurteilen zu können, benötigen wir Planunterlagen. Es ist daher erforderlich, dass Sie uns die Pläne und eine Vollmacht, ggf. zur selbstständigen Erhebung der Pläne, zukommen lassen.")]), _c('h4', [_vm._v("Pläne hochladen:")]), _vm._m(5), _c('h4', [_vm._v("Vollmacht ausstellen:")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Des Weiteren bitte ich Sie uns eine Vollmacht je Adresse zukommen lassen, damit im Falle Unvollständigkeiten die Pläne seitens Consense Consulting beim zuständigen Magistrat/Bauamt erhoben werden können. ")]), _vm._m(6), _c('p', [_vm._v("Öffnen Sie die diese Vorlage und drucken Sie diese aus. Bitte unterzeichnen Sie die Vollmacht (inkl. Klarnamen), scannen diese wieder ein und laden Sie sie auf dieser Seite neben der weiter unten angeführten jeweiligen Adresse hoch (Button „Vollmacht hochladen“).")]), _vm._m(7), _c('p', [_vm._v("Öffnen Sie die diese Vorlage und drucken Sie diese aus. Bitte unterzeichnen Sie die Vollmacht (inkl. Klarnamen), scannen diese wieder ein und laden Sie sie auf dieser Seite neben der weiter unten angeführten jeweiligen Adresse hoch (Button „Vollmacht hochladen“).")]), _vm._m(8), _vm._m(9), _vm._m(10), _c('div', {
    staticClass: "bold redp"
  }, [_vm._v("Besonders wichtig ist für uns die Information, ob das Gebäude unter Denkmalschutz steht!")]), _c('p'), _vm._m(11), _c('p', {
    staticClass: "pad bold"
  }, [_c('a', {
    attrs: {
      "href": _vm.$env.mainurl + "free/Vollmacht-Consense.pdf",
      "target": "_blank"
    }
  }, [_vm._v("Allgemeine Vollmacht Vorlage HIER ausdrucken")])]), _c('br'), !_vm.isLoaded ? _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "loading-spinner"
  })]) : _vm.isLoaded ? _c('ul', [Object.keys(_vm.allProjectsFromHv).length === 0 ? _c('li', [_c('div', {
    staticClass: "_redp"
  }, [_vm._v("keine Akte zugeordnet")])]) : _vm._e(), _vm._l(_vm.allProjectsFromHv, function (doc, i) {
    return _c('li', [doc.id ? _c('span', [_c('div', {
      staticClass: "topline"
    }, [_c('h5', {
      staticClass: "bold"
    }, [_vm._v("Objekt:"), _c('div', {
      staticClass: "fr"
    }, [_c('small', [_vm._v("ID: " + _vm._s(doc.id.substring(0, 6)))])])])]), _c('div', {
      staticClass: "fl"
    }, [_c('span', {
      staticClass: "pad bold"
    }, [_vm._v(_vm._s(doc.hvData.vnaddress.name)), _c('br'), _vm._v(_vm._s(doc.hvData.address.street) + " "), _c('br'), _vm._v(_vm._s(doc.hvData.address.plz) + " " + _vm._s(doc.hvData.address.city))])]), _c('div', {
      staticClass: "fr tar"
    }, [_c('a', {
      staticClass: "bold",
      attrs: {
        "href": '/#/akte/' + _vm.currentHvUid + '/' + doc.id,
        "target": "_blank"
      }
    }, [_vm._v("Zum Fragebogen")]), _c('br'), doc.hvData.modified > 1546300800001 ? _c('small', {
      staticClass: "bold _redp"
    }, [_vm._v("bearbeitet: " + _vm._s(_vm.$dayjs(doc.hvData.modified).format('DD.MM.YYYY HH:mm')))]) : _vm._e()]), _c('div', {
      staticClass: "clear"
    }), _c('p'), _c('div', {
      staticClass: "file-upload"
    }, [_c('label', {
      staticClass: "file-label",
      attrs: {
        "for": 'allianzfilesv' + doc.id
      }
    }, [((_vm.touploadfiles[doc.id] || {}).vollmacht || []).length == 0 ? _c('span', {
      staticClass: "link"
    }, [_vm._v("Vollmacht auswählen")]) : _vm._e(), _vm._l((_vm.touploadfiles[doc.id] || {}).vollmacht || [], function (file) {
      return _c('span', {
        staticClass: "file"
      }, [_vm._v(_vm._s(file.name))]);
    }), ((_vm.touploadfiles[doc.id] || {}).vollmacht || []).length > 0 ? _c('span', {
      staticClass: "uploadbtn bold link",
      on: {
        "click": function click($event) {
          return _vm.sendHvwFiles(doc.id, 'vollmacht');
        }
      }
    }, [_vm._v("Hochladen")]) : _vm._e()], 2), _c('input', {
      staticClass: "file-input",
      attrs: {
        "type": "file",
        "name": 'allianzfilesv' + doc.id,
        "accept": "image/*, application/pdf",
        "multiple": ""
      },
      on: {
        "change": function change($event) {
          return _vm.addFiles(doc.id, 'vollmacht', $event);
        }
      }
    })]), _c('div', {
      staticClass: "file-upload"
    }, [_c('label', {
      staticClass: "file-label",
      attrs: {
        "for": 'allianzfilesp' + doc.id
      }
    }, [((_vm.touploadfiles[doc.id] || {}).plane || []).length == 0 ? _c('span', {
      staticClass: "link"
    }, [_vm._v("Pläne auswählen")]) : _vm._e(), _vm._l((_vm.touploadfiles[doc.id] || {}).plane || [], function (file) {
      return _c('span', {
        staticClass: "file"
      }, [_vm._v(_vm._s(file.name))]);
    }), ((_vm.touploadfiles[doc.id] || {}).plane || []).length > 0 ? _c('span', {
      staticClass: "uploadbtn bold link",
      on: {
        "click": function click($event) {
          return _vm.sendHvwFiles(doc.id, 'plane');
        }
      }
    }, [_vm._v("Hochladen")]) : _vm._e()], 2), _c('input', {
      staticClass: "file-input",
      attrs: {
        "type": "file",
        "name": 'allianzfilesp' + doc.id,
        "accept": "image/*, application/pdf",
        "multiple": ""
      },
      on: {
        "change": function change($event) {
          return _vm.addFiles(doc.id, 'vollmacht', $event);
        }
      }
    })]), _c('p'), _c('div', {
      staticClass: "bold"
    }, [_vm._v("bereits hochgeladen:")]), _vm.allfiles[doc.id] ? _c('ul', [_vm._l(_vm.allfiles[doc.id], function (fi) {
      return _c('li', {
        staticClass: "uppedfile"
      }, [_c('a', {
        attrs: {
          "href": _vm.$env.mainurl + fi.path,
          "target": "_blank"
        }
      }, [_vm._v(_vm._s(fi.name))])]);
    }), _vm._m(12, true)], 2) : _c('span', [_vm._v("Noch keine Dateien vorhanden!")]), _c('div', {
      staticClass: "clear"
    })]) : doc.notcreated ? _c('p', {
      staticClass: "bold _redp"
    }, [_vm._v(" ID: " + _vm._s(doc.notcreated) + " - keine HV Vorlage vorhanden!")]) : doc.closed ? _c('p', {
      staticClass: "bold _redp"
    }, [_vm._v("ID: " + _vm._s(doc.closed) + " - für Bearbeitung gesperrt!")]) : _c('p', {
      staticClass: "bold _redp"
    }, [_vm._v("Fehlerhafters Dokument")])]);
  })], 2) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "spacer _vertical _large"
  })]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('h1', {
    staticClass: "pad"
  }, [_vm._v("Sehr geehrte Eigentümerinnen und Eigentümer,"), _c('br'), _vm._v("sehr geehrte Hausverwaltung,")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "pad"
  }, [_vm._v("wir, das Sachverständigenbüro Consense (www.consense.at), wurden von der Allianz Versicherung beauftragt, die Gebäude an den unten angeführten Adressen zu bewerten, um die Versicherungssumme zu evaluieren. Für diese Tätigkeit benötigen wir Ihre Unterstützung."), _c('br')]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "pad"
  }, [_vm._v("Bei etwaigen Fragen wenden Sie sich bitte an die zuständige Ansprechperson in unserem Büro:"), _c('br')]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "pad"
  }, [_c('ul', {
    staticClass: "head bold"
  }, [_c('li', [_c('span', [_vm._v("Marina Söllner")]), _c('br'), _c('span', [_vm._v("Tel.: +43 (1) 270 41 04 319")]), _c('br'), _c('span', [_vm._v("E-Mail: m.soellner@consense.at ")])]), _c('li', [_c('span', [_vm._v("Emilia Flasch")]), _c('br'), _c('span', [_vm._v("Tel.: +43 (1) 270 41 04 320")]), _c('br'), _c('span', [_vm._v("E-Mail: e.flasch@consense.at ")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("Schritt 1: Beschaffung Planunterlagen")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "pad"
  }, [_vm._v("Falls Ihnen Bestandspläne "), _c('span', {
    staticClass: "bold _redp"
  }, [_vm._v(" (bemaßte Grundrisse von jedem Geschoß, sowie Gebäudeschnitt mit ersichtlichen Geschosshöhen) ")]), _vm._v(" aller auf dem Grundstück befindlichen Gebäude in digitaler Form vorliegen, bitten wir Sie, diese hier hochzuladen. Dazu klicken Sie bitte auf den Button „Pläne hochladen“ neben der jeweiligen, unten angeführten Adresse und wählen die Datei aus, die Sie uns übermitteln wollen. Falls Ihnen eine Nutzflächenaufstellung oder Mieterliste (mit m² Angeben) vorliegt, bitten wir Sie, diese auf dieselbe Weise hochzuladen.")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "pad bold"
  }, [_vm._v("Falls Sie "), _c('span', {
    staticClass: "underline"
  }, [_vm._v("Eigentümer ")]), _vm._v("des Gebäudes sind, bitten wir Folgendes zu beachten:")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "pad bold"
  }, [_vm._v("Falls Sie die "), _c('span', {
    staticClass: "underline"
  }, [_vm._v("Hausverwaltung ")]), _vm._v("des Gebäudes sind, bitte wir Folgendes zu beachten:")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('span', {
    staticClass: "bold _redp"
  }, [_vm._v("Achtung: ")]), _vm._v(" Zusätzlich benötigen wir die Vollmacht, mit welcher der/die Hauseigentümer Sie als Hausverwaltung bevollmächtigt hat. (d.h. in Summe 2 Vollmachten). Diese muss von unserem Büro bei der Planeinsicht am zuständigen Magistrat/Bauamt ebenfalls vorgezeigt werden. Bitte laden Sie diese ebenfalls hoch (Button „Vollmacht hochladen“).")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("Schritt 2: Fragebogen zur Gebäudebewertung")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "pad"
  }, [_vm._v("Um die Besichtigung des/der Gebäude so effizient wie möglich zu gestalten, würden wir Sie bitten, einen Fragebogen je Adresse auszufüllen. Klicken Sie hierzu auf den Button"), _c('span', {
    staticClass: "bold"
  }, [_vm._v("„Zum Fragebogen“ ")]), _vm._v("neben der jeweiligen Adresse und beantworten Sie alle Fragen, zu denen Ihnen Informationen vorliegen. ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("Liste der Objekte")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', [_c('small', [_vm._v("Zum Löschen falsch hochgeladener Dateien, bitte das Consense Office kontaktieren.")])]);
}];
export { render, staticRenderFns };